import React, { useEffect, useState } from 'react';
import { WastewaterContaminant } from '../../types/WastewaterContaminant';
import { getAllWastewaterContaminants } from '../../services/WasteWaterService';
import { LineChart } from '@mui/x-charts';

const WastewaterCarbonGraph: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [carbonData, setCarbonData] = useState<{ date: string; value: number }[]>([]);

    useEffect(() => {
        const fetchCarbonData = async () => {
            setLoading(true);
            try {
                const data = await getAllWastewaterContaminants();

                // Filter for 'CARBON, DISSOLVED ORGANIC' contaminant type and parse date
                const carbonEntries = data
                    .filter((item: WastewaterContaminant) => item.contaminant === 'CARBON, DISSOLVED ORGANIC')
                    .map((item) => ({
                        date: item.sampleDate, // Keep as a string to match x-axis data type requirements
                        value: item.value,
                    }));

                setCarbonData(carbonEntries);
            } catch (error) {
                console.log('Error fetching carbon data:', error);
                setError('Error fetching carbon data');
            } finally {
                setLoading(false);
            }
        };

        fetchCarbonData();
    }, []);

    if (loading) {
        return <p>Loading data...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (carbonData.length === 0) {
        return <p>No carbon data available.</p>;
    }

    return (
        <div className="carbon-graph-container">
            <h2>Carbon Levels Over Time In NOVA CHEMICALS CANADA LTD. (MOORETOWN) (SARNIA)</h2>
            <LineChart
                width={800}
                height={400}
                series={[
                    {
                        data: carbonData.map(item => item.value), // y-axis values
                        label: 'Carbon Level (KG/D)',
                    },
                ]}
                xAxis={[
                    {
                        data: carbonData.map(item => item.date), // x-axis dates
                        scaleType: 'band',
                        label: 'Date',
                    },
                ]}
                yAxis={[
                    {
                        label: 'Carbon (KG/D)',
                    },
                ]}
            />
            <p className="line-graph-note">*Note: Carbon levels over time (in KG/D).</p>
        </div>
    );
};

export default WastewaterCarbonGraph;
