import React, { useEffect, useState } from 'react';
import { DistrictAverage, SewageExceedance } from '../../types/SewageExceedance';
import { getAverageExceedancesByMunicipality, getAverageExceedancesPerDistrict } from '../../services/SewageService';
import { BarChart, PieChart } from '@mui/x-charts';

interface SewageDataTableProps {
    sewageData: SewageExceedance[];
}

const SewageExceedanceGraph: React.FC<SewageDataTableProps> = ({ sewageData }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [averages, setAverages] = useState<{ municipality: string; avg: number }[]>([]);
    const [averagePerDistrict, setAveragePerDistrict] = useState<DistrictAverage[]>([]);

    const [dropDownValue, setDropDownValue] = useState<string>("Municipality");

    const uniqueMunicipalities = sewageData.reduce((acc: string[], current) => {
        if (!acc.includes(current.municipality)) {
            acc.push(current.municipality);
        }
        return acc;
    }, []);

    useEffect(() => {
        const fetchAverageExceedances = async () => {
            setLoading(true);
            try {
                const averagesData = await Promise.all(
                    uniqueMunicipalities.map(async (municipality) => {
                        const encodedMunicipality = encodeURIComponent(municipality);
                        const avg = await getAverageExceedancesByMunicipality(encodedMunicipality);
                        return avg > 1 ? { municipality, avg } : null;
                    })
                );
                setAverages(averagesData.filter((item): item is {municipality: string; avg: number} => item !== null));
            } catch (error) {
                setError('Error fetching average exceedances');
            } finally {
                setLoading(false);
            }
        };
        fetchAverageExceedances();
        setLoading(false);
    }, []);

    useEffect(() => {
        const fetchAveragePerDistrict = async () => {
            setLoading(true);
            try {
                const avg = await getAverageExceedancesPerDistrict();
                if (Array.isArray(avg)) {
                    setAveragePerDistrict(avg);
                } else {
                    throw new Error("Unexpected data format");
                }
            } catch (error) {
                setError('Error fetching average exceedances');
            } finally {
                setLoading(false);
            }
        };
        fetchAveragePerDistrict();
    }, []);
    
    const averageData = averages.map(item => item.avg);
    const pieChartDataMunicipality = averages
    .filter(item => item.avg > 3)
    .map(item => ({
        label: item.municipality,
        value: item.avg,
    }));

    const pieChartDataDistrict = averagePerDistrict
    .filter(item => item.exceedance > 1)
    .map(item => ({
        label: item.district,
        value: item.exceedance,
    }));

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {        
        setDropDownValue(event.target.value);
    };

    return (
        <div className='sewage-graph-container'>
            <h2>{dropDownValue} vs Average Number of Exceedances Bar Graph</h2>
            <div>
                <select name='district-municipality' onChange={handleDropdownChange}>
                    <option value="Municipality">Municipality</option>
                    <option value="District">District</option>
                </select>
            </div>
            {dropDownValue === "Municipality" ? (
                <div>
                    <BarChart
                        width={800}
                        height={400}
                        series={[
                            { data: averageData },
                        ]}
                        xAxis={[{ data: averages.map(item => item.municipality), scaleType: 'band' }]}
                    />
                    <p className='bar-graph-note'>*Note: only included municipalities with more than 1 exceedance for more valuable data.</p>

                    <h2>Municipality vs Average Number of Exceedances Pie Chart</h2>
                    <PieChart
                        width={800}
                        height={400}
                        series={[
                            {
                                data: pieChartDataMunicipality,
                            }
                        ]}
                    />
                    <p className='pie-graph-note'>*Note: Pie chart shows average exceedances greater than 3</p>
                </div>
            ) : (
                <div>
                    <BarChart
                        width={800}
                        height={400}
                        series={[
                            { data: averagePerDistrict.map(item => item.exceedance) },
                        ]}
                        xAxis={[{ data: averagePerDistrict.map(item => item.district), scaleType: 'band' }]}
                    />
                    <h2>District vs Average Number of Exceedances Pie Chart</h2>
                        <PieChart
                            width={800}
                            height={400}
                            series={[
                                {
                                    data: pieChartDataDistrict,
                                }
                            ]}
                        />
                        <p className='pie-graph-note'>*Note: Pie chart shows average exceedances greater than 1</p>
                    </div>
            )}
        </div>
    );
};

export default SewageExceedanceGraph;
