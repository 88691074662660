import { WastewaterContaminant } from '../types/WastewaterContaminant';

const API_URL = '/api/wastewater';

// Method to fetch all wastewater contaminants
export const getAllWastewaterContaminants = async (): Promise<WastewaterContaminant[]> => {
  try {
    const response = await fetch(`${API_URL}/all`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Fetched wastewater data:', data); // Debugging information
    return data;
  } catch (error) {
    console.log('Error fetching wastewater data:', error);
    return [];
  }
};
