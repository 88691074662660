import React from 'react';

const SplashPage: React.FC = () => {

  return (
    <div id='product-pitch-div'>
      <h3>Introducing the WaterWise application: The Wastewater and Sewage Compliance Monitor</h3>
      <p>
      WaterWise is an innovative compliance dashboard that tracks and visualizes regions with non-compliance in
      both sewage and industrial wastewater emissions. Our platform empowers users to monitor combined pressures
      on local water systems and environmental health with real-time, interactive data.
      </p>
      <p>
      Designed for environmentalists, researchers, and engineers, WaterWise helps users identify problem areas,
      ensure regulatory compliance, and make informed decisions that protect our water resources. Whether you're
      working in environmental regulation, wastewater management, or research, WaterWise gives you the tools to
      visualize critical data and act swiftly to safeguard water quality and public health.
      </p>
    </div>
  );
};

export default SplashPage;
