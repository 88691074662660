// WastewaterTable.tsx
import React, { useState, useEffect } from 'react';
import { WastewaterContaminant } from '../types/WastewaterContaminant';
import { getAllWastewaterContaminants } from '../services/WasteWaterService';
import  WastewaterCarbonGraph  from '../components/visualization_components/WastewaterCarbonGraph'
const WastewaterTable: React.FC = () => {
const [wastewaterData, setWastewaterData] = useState<WastewaterContaminant[]>([]);
const [loading, setLoading] = useState<boolean>(true);
const [error, setError] = useState<string | null>(null);

  // Fetch wastewater data initially
  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    const fetchWastewaterData = async () => {
      setLoading(true); // Set loading to true at the start
      try {
        console.log('Fetching wastewater data...');
        const data = await getAllWastewaterContaminants();
        console.log('Wastewater data received:', data); // Debugging information
        if (isMounted) { // Check if still mounted before updating state
          setWastewaterData(data);
        }
      } catch (error) {
        console.log('Error fetching wastewater data:', error);
        if (isMounted) { // Check if still mounted before updating state
          setError('Error fetching wastewater data');
        }
      } finally {
        if (isMounted) { // Check if still mounted before updating state
          setLoading(false);
        }
      }
    };
    fetchWastewaterData();
    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, []);

  if (loading) {
    return <p data-testid="loading-spinner">Loading data...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!wastewaterData || wastewaterData.length === 0) {
    return <p className="no-data">No wastewater contaminant data available.</p>;
  }

  return (
    <div className="sewage-table-container">
        <WastewaterCarbonGraph/>
      <h2 className="title">Wastewater Contaminant Data</h2>
      <table className="sewage-table">
        <thead>
          <tr>
            <th>Facility</th>
            <th>Sample Date</th>
            <th>Contaminant</th>
            <th>Reported As</th>
            <th>Component Type</th>
            <th>Value</th>
            <th>Unit</th>
          </tr>
        </thead>
        <tbody>
          {wastewaterData.map((item) => (
            <tr key={item.id}>
              <td>{item.facility}</td>
              <td>{item.sampleDate}</td>
              <td>{item.contaminant}</td>
              <td>{item.contaminantExpression}</td>
              <td>{item.componentType}</td>
              <td>{item.value}</td>
              <td>{item.unit}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WastewaterTable;
