import React, { useState, useEffect } from 'react';
// Import the service functions
import { getAllExceedances, getAverageExceedancesByMunicipality } from '../services/SewageService';
import { SewageExceedance } from '../types/SewageExceedance';
import SewageExceedanceGraph from './visualization_components/SewageExceedanceGraph';

const SewageTable: React.FC = () => {
const [sewageData, setSewageData] = useState<SewageExceedance[]>([]);
const [loading, setLoading] = useState<boolean>(true);
const [error, setError] = useState<string | null>(null);
const [municipality, setMunicipality] = useState<string>(''); // State for municipality input
const [averageExceedances, setAverageExceedances] = useState<number | null>(null); // State for storing average exceedances
const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // State for sorting by number of exceedances
  
  // Fetch sewage data initially
  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    const fetchSewageData = async () => {
      setLoading(true); // Set loading to true at the start
      try {
        console.log('Fetching sewage data...');
        const data = await getAllExceedances();
        console.log('Sewage data received:', data); // Debugging information
        if (isMounted) { // Check if still mounted before updating state
          setSewageData(data);
        }
      } catch (error) {
        console.log('Error fetching sewage data:', error);
        if (isMounted) { // Check if still mounted before updating state
          setError('Error fetching sewage data');
        }
      } finally {
        if (isMounted) { // Check if still mounted before updating state
          setLoading(false);
        }
      }
    };
  
    fetchSewageData();
  
    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, []);

  // Fetch average exceedances by municipality
  const fetchAverageExceedances = async (municipality: string) => {
    setLoading(true); // Set loading state while fetching
    try {
      const avg = await getAverageExceedancesByMunicipality(municipality);
      setAverageExceedances(avg); // Set the fetched average exceedances
    } catch (error) {
      setError('Error fetching average exceedances');
    } finally {
      setLoading(false); // Remove loading state
    }
  };

  // Sorts data in either ascending or descending order
  const sortData = (order: 'asc' | 'desc') => {
    /* creates a copy of sewageData using the spread operator [...]
       then applies the .sort() method to compare elements in the array */
    const sortedData = [...sewageData].sort((a, b) => {
      return order === 'asc' ? a.exceedances - b.exceedances : b.exceedances - a.exceedances;
    });
    setSewageData(sortedData); // updates component state w/ new sorted array
    setSortOrder(order); // updates sortOrder to keep track of current sorting direction
  };

  if (loading) {
    return <p data-testid="loading-spinner">Loading data...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!sewageData || sewageData.length === 0) {
    return <p className="no-data">No sewage exceedance data available.</p>;
  }

  return (
    <div className="sewage-table-container">
      <h2 className="title">Sewage Exceedance Data</h2>

      {/* Bar graph which shows the relationship between municipalities and the average exceedances*/}
      <SewageExceedanceGraph sewageData={sewageData} />

      {/* Added the section for fetching average exceedances */}
      <div>
        <label htmlFor="municipality">Select Municipality:</label>
        <input
          type="text"
          id="municipality"
          value={municipality}
          onChange={(e) => setMunicipality(e.target.value)}
        />
        <button onClick={() => fetchAverageExceedances(municipality)}>Get Average Exceedances</button>
      </div>

      {/* Display average exceedances */}
      {averageExceedances !== null && (
        <div>
          <h3>Average Exceedances for {municipality}:</h3>
          <p>{averageExceedances}</p>
        </div>
      )}

      {/* Existing sewage data table */}
      <table className="sewage-table">
        <thead>
          <tr>
            <th>Municipality</th>
            <th>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Exceedances</span>
                <div className="filter-buttons" style={{ marginLeft: '10px' }}>
                  <button onClick={() => sortData('asc')}>Sort Ascending</button>
                  <button onClick={() => sortData('desc')}>Sort Descending</button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {sewageData.map((item) => (
            <tr key={item.id}>
              <td>{item.municipality}</td>
              <td>{item.exceedances}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SewageTable;