import React, { useState } from 'react';
import SplashPage from './components/SplashPage';
import SewageTable from './components/SewageTable';
import WastewaterTable from './components/WasteWaterTable';

const App: React.FC = () => {
  // State to manage which table is shown
  const [showTable, setShowTable] = useState<'sewage' | 'wastewater'>('sewage');

  return (
    <div className="App">
      <h1>WaterWise</h1>
      <SplashPage />

      {/* Buttons to switch between tables */}
      <div>
        <button onClick={() => setShowTable('sewage')}>Show Sewage Table</button>
        <button onClick={() => setShowTable('wastewater')}>Show Wastewater Table</button>
      </div>

      {/* Conditionally render the tables based on the state */}
      {showTable === 'sewage' ? <SewageTable /> : <WastewaterTable />}
    </div>
  );
};

export default App;
