import { DistrictAverage, SewageExceedance } from '../types/SewageExceedance';

const API_URL = '/api/sewage';

// Existing method to fetch all exceedances
export const getAllExceedances = async (): Promise<SewageExceedance[]> => {
  try {
    const response = await fetch(`${API_URL}/all`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Fetched sewage data:', data); // Debugging information
    return data;
  } catch (error) {
    console.log('Error fetching sewage data:', error);
    return [];
  }
};

// New method to fetch average exceedances for a given municipality
export const getAverageExceedancesByMunicipality = async (municipality: string): Promise<number> => {
  try {
    const response = await fetch(`${API_URL}/average/${municipality}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Fetched average exceedances:', data);
    return data;
  } catch (error) {
    console.log('Error fetching average exceedances:', error);
    return 0;
  }
};

export const getAverageExceedancesPerDistrict = async (): Promise<DistrictAverage[]> => {
  try {
    const response = await fetch(`${API_URL}/average/district`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data: [string, number][] = await response.json();
    console.log('Fetched average exceedances per district:', data);

    const formattedData = data.map(([district, exceedance]) => ({
      district,
      exceedance,
    }));

    return formattedData;
  } catch (error) {
    console.log('Error fetching average exceedances:', error);
    return [];
  }
}
